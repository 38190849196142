.menu {
  .subject {
    position: relative;
    padding: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.2s;
    font-size: 14px;
    font-weight: 500;
    color: var(--greyish2);

    &:hover {
      background-color: var(--quinary);
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%) rotate(-45deg);
      transition: 0.2s transform;
      width: 8px;
      height: 8px;
      border-right: 2px solid var(--greyish4);
      border-bottom: 2px solid var(--greyish4);
      box-sizing: border-box;
    }

    &.active {
      background-color: var(--quinary);
      &::after {
        transform: translateY(-50%) rotate(45deg);
      }
    }

    &.link {
      &::after {
        content: unset;
      }
    }

    img {
      width: 20px;
      margin-right: 6px;
    }
  }

  .submenu {
    overflow: hidden;
    height: 0;
    background-color: var(--greyish11);
    transition: height 0.2s;
  }
}
