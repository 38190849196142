.table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 8px;

  thead {
    tr {
      th {
        padding: 8px 0;
        border-bottom: 1px solid var(--greyish9);
        font-size: 14px;
        font-weight: 400;
        color: var(--greyish3);
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 16px 0;
        border-bottom: 1px solid var(--greyish9);
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          color: var(--greyish3);

          .icon {
            margin-right: 4px;
            cursor: pointer;

            &.disabled {
              cursor: default;
              opacity: 0;
            }
          }
        }

        .checkbox {
          position: relative;
          margin: 0 auto;
          width: 18px;
          height: 18px;
          box-sizing: border-box;
          border: 1px solid var(--greyish8);
          cursor: pointer;

          &::after {
            content: '';
            position: absolute;
            top: calc(50% - 1px);
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 7px;
            height: 4px;
            border-left: 2px solid white;
            border-bottom: 2px solid white;
          }

          &.active {
            border: 1px solid var(--primary);
            background-color: var(--primary);
          }
        }
      }
    }
  }
}

.table-tools {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: unset;

    .search {
      margin-top: 8px;

      input {
        flex: 1;
      }
    }
  }

  .filter {
    position: relative;
    width: 160px;
    border: 1px solid var(--greyish8);
    box-sizing: border-box;

    @media screen and (max-width: 900px) {
      margin-top: 8px;
      width: 100%;
    }

    select {
      display: block;
      appearance: none;
      width: 100%;
      height: 100%;
      padding: 2px 8px;
      font-size: 14px;
      color: var(--greyish3);
    }

    .selector {
      cursor: pointer;
      box-sizing: border-box;
      width: 100%;
      height: 24px;
      padding: 2px 8px;
      font-size: 14px;
      color: var(--greyish3);
    }

    .arrow {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%) rotate(45deg);
      width: 8px;
      height: 8px;
      border-bottom: 2px solid var(--greyish8);
      border-right: 2px solid var(--greyish8);
      box-sizing: border-box;
      pointer-events: none;
    }
  }

  .search {
    // display: flex;
    display: none;
    align-items: center;
    border: 1px solid var(--greyish8);
    input {
      padding: 4px 0 4px 8px;
    }
    img {
      width: 24px;
      padding: 2px;
    }
  }

  .btns {
    button {
      margin-left: unset;
    }
  }

  .selected {
    font-size: 12px;
    color: var(--tertiary);
  }

  .warning {
    font-size: 12px;
    color: var(--primary);
  }
}

.formItem {
  display: flex;
  align-items: center;
  margin-top: 12px;
  min-height: 40px;

  .subject {
    box-sizing: border-box;
    padding: 0 12px;
    width: 160px;
    font-size: 14px;
    font-weight: 500;
    color: var(--greyish3);

    u {
      margin-left: 3px;
      font-size: 12px;
      color: var(--tertiary);
    }

    @media screen and (max-width: 900px) {
      display: none;
    }

    .warning {
      font-size: 11px;
      color: var(--secondary);
    }
  }

  .option {
    flex: 1;
    min-height: 40px;

    .switch {
      display: flex;
      align-items: center;
      justify-content: unset;
      height: 40px;
      padding: unset;

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 72px;
        height: 28px;
        padding: 0 16px;
        cursor: pointer;
        background-color: var(--greyish8);
        font-size: 14px;
        color: white;

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }

        &.active {
          background-color: var(--btn-primary);
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: unset;
      height: 40px;
      padding: unset;
    }

    .radio {
      display: flex;
      align-items: center;
      height: 40px;

      label {
        display: flex;
        font-size: 14px;
        color: var(--greyish3);
        &:first-child {
          margin-right: 16px;
        }
        input {
          appearance: auto;
        }
      }
    }

    .input {
      display: flex;
      align-items: center;
      min-height: 40px;
      position: relative;

      input {
        width: 100%;
        border: 1px solid var(--greyish8);
        border-radius: 4px;
        padding: 0 12px;
        height: 32px;

        &::placeholder {
          color: var(--greyish8);
        }
      }

      textarea {
        width: 100%;
        border: 1px solid var(--greyish8);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 8px;
        min-height: 100px;
        resize: none;
      }

      select {
        appearance: none;
        width: 100%;
        border: 1px solid var(--greyish8);
        border-radius: 4px;
        padding: 0 12px;
        height: 32px;
      }

      .arrow {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%) rotate(45deg);
        width: 8px;
        height: 8px;
        border-bottom: 2px solid var(--greyish8);
        border-right: 2px solid var(--greyish8);
        box-sizing: border-box;
        pointer-events: none;
      }
    }

    .file {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 40px;
      position: relative;

      .uploaded {
        display: flex;
        width: 200px;
        margin-bottom: 12px;

        img {
          width: 100%;
        }
      }

      label {
        position: relative;
        width: 100%;
        border: 1px solid var(--greyish8);
        border-radius: 4px;
        padding: 0 12px;
        height: 32px;
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;

        .placeholder {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          color: var(--greyish8);
        }

        .text {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          overflow: hidden;
        }
      }
    }

    .validation {
      display: flex;
      flex-direction: column;

      .item {
        margin-top: 4px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #ff5656;

        &.active {
          color: #0085f9;
        }

        img {
          width: 16px;
        }
      }
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 12px;
      .item {
        position: relative;
        display: flex;
        margin-top: 12px;
        padding: 8px 4px 8px 8px;
        background-color: white;
        border: 1px solid var(--greyish8);
        border-radius: 4px;
        cursor: pointer;

        .text {
          font-size: 14px;
          color: var(--greyish5);
        }

        .check {
          margin-left: 4px;
          position: relative;
          width: 20px;
          height: 20px;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            width: 8px;
            height: 4px;
            border-bottom: 2px solid var(--greyish8);
            border-left: 2px solid var(--greyish8);
          }
        }

        &.active {
          border: 1px solid var(--primary);
          background-color: var(--primary);

          .text {
            color: white;
          }

          .check {
            &::after {
              border-bottom: 2px solid white;
              border-left: 2px solid white;
            }
          }
        }
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
    min-height: 32px;
    position: relative;
    border: 1px solid var(--greyish8);
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    input {
      padding: 0 0 0 8px;
      height: 32px;
      box-sizing: border-box;
      width: 100%;
    }
    img {
      width: 32px;
      padding: 4px;
      cursor: pointer;
    }
  }
}

.table-modal {
  position: relative;
  margin: 80px auto;
  padding: 16px;
  background-color: white;
  width: 600px;
  max-height: 800px;
  overflow-y: auto;

  .title {
    font-size: 16px;
    color: var(--greyish2);
  }
}

.form-modal {
  position: relative;
  margin: 80px auto;
  padding: 16px;
  background-color: white;
  width: 600px;
  overflow-y: auto;

  .title {
    font-size: 16px;
    color: var(--greyish2);
  }
}

.formSubmit {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.analysis {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 1200px) {
    display: block;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    padding: 16px 0;
    border-top: 1px solid var(--greyish8);
    min-height: 40px;
    display: flex;
    align-items: center;
    font-size: 13px;
    text-align: center;
    color: var(--greyish3);
  }
}
