.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 5;

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
    height: 100%;
    width: 80%;
    max-width: 320px;
    margin: 16px 0;
    .logo {
      border-bottom: 1px solid var(--primary);
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    .message {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: var(--greyish4);
      word-break: keep-all;
      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
        color: var(--greyish2);
      }
    }

    .btn {
      background-color: var(--primary);
      border-radius: 4px;
      cursor: pointer;
      padding: 0 12px;
      font-size: 14px;
      color: white;
      height: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .temi {
    width: 80%;
    max-width: 300px;
  }
}
