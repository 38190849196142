.modal {
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  width: 320px;
  display: flex;
  flex-direction: column;

  .title {
    background-color: var(--secondary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 8px 12px;
    box-sizing: border-box;
    color: white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    img {
      cursor: pointer;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
    row-gap: 5px;
    word-break: break-word;

    .message {
      padding: 16px 8px 8px 8px;
      white-space: pre-wrap;
      font-size: 14px;
      text-align: center;
      color: var(--greyish1);
      overflow: auto;
      span {
        font-weight: bold;
        color: var(--secondary);
      }

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--greyish3);
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }

    .input {
      padding: 0 16px 8px 16px;

      input {
        width: 100%;
        border: 1px solid var(--greyish8);
        padding: 4px 8px;
      }
    }

    .select {
      padding: 0 8px 8px 8px;

      select {
        width: 100%;
        padding: 4px 8px;
        border: 1px solid var(--greyish8);
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 8px 16px;

      .btn {
        padding: 6px 12px;
        border-radius: 4px;
        background-color: var(--secondary);
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        color: white;

        &:nth-child(2) {
          background-color: var(--greyish5);
          margin-left: 8px;
        }
      }
    }
  }
}
