.container {
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid var(--greyish9);

  .inner {
    width: 100%;

    .contents {
      background-color: white;
      height: 56px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .start {
        display: flex;
        align-items: center;

        .menu {
          padding: 8px;
          cursor: pointer;
        }

        .logo {
          width: 120px;
          padding: 0 8px;

          img {
            display: flex;
            align-items: center;
            width: 100%;
            cursor: pointer;
          }
        }
      }

      .center {
        display: flex;
        align-items: center;
        flex: 1;
        margin: 0 4px;
        font-size: 14px;
        font-weight: 500;
        color: var(--secondary);

        .passChange {
          display: inline-flex;
          align-items: center;
          margin-left: auto;
          border: 1px solid var(--btn-warning);
          padding: 4px 8px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          color: var(--btn-warning);

          @media screen and (max-width: 480px) {
            font-size: 12px;
          }

          @media screen and (max-width: 360px) {
            font-size: 10px;
          }
        }
      }

      .end {
        display: flex;
        align-items: center;

        .logout {
          display: flex;
          align-items: center;
          border: 1px solid var(--greyish3);
          padding: 4px 8px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          color: var(--greyish3);

          img {
            margin-right: 4px;
            width: 20px;
          }

          @media screen and (max-width: 480px) {
            img {
              margin-right: unset;
            }
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
