@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

:root {
  --primary: #006bbc;
  --secondary: #0068e5;
  --tertiary: #009fe8;
  --greyish1: #1d1d1b;
  --greyish2: #333333;
  --greyish3: #6b6b6b;
  --greyish4: #8a8c8f;
  --greyish5: #a6a9ac;
  --greyish6: #bbbbbb;
  --greyish7: #cccccc;
  --greyish8: #d9dadb;
  --greyish9: #eeeeee;
  --greyish10: #f6f6f6;
  --greyish11: #fafafa;
  --hover: rgba(119, 201, 255, 0.15);
  --btn-primary: #4f93dc;
  --btn-secondary: #7b8c9a;
  --btn-success: #67bf7c;
  --btn-danger: #d54554;
  --btn-warning: #feb24e;
  --btn-info: #51b0c3;
  --btn-light: #f8f9fa;
  --btn-dark: #343a40;
}

* {
  box-sizing: border-box;
  outline: 0;
  text-decoration: none;
  outline: 0;
  border: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
  list-style: none;
  font-family: 'Noto Sans KR', sans-serif;
}

button,
input,
textarea {
  appearance: none;
  border-radius: 0;
}

input,
textarea {
  user-select: auto;
}

img {
  -webkit-user-drag: none;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  overflow: hidden;
  -webkit-text-size-adjust: 100%;
}

body {
  position: fixed;
  overflow: hidden;
  touch-action: none;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;

  @media screen and (orientation: landscape) {
    padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  }
}

button {
  cursor: pointer;
  border-radius: 4px;
  margin: 0 4px;
  padding: 4px 8px;
  box-sizing: border-box;
  background-color: white;
  transition: background-color 0.1s, color 0.2s;
  font-size: 12px;
  font-weight: 400;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.primary {
    border: 1px solid var(--btn-primary);
    color: var(--btn-primary);

    &:hover {
      background-color: var(--btn-primary);
      color: white;
    }
  }

  &.secondary {
    border: 1px solid var(--btn-secondary);
    color: var(--btn-secondary);

    &:hover {
      background-color: var(--btn-secondary);
      color: white;
    }
  }

  &.success {
    border: 1px solid var(--btn-success);
    color: var(--btn-success);

    &:hover {
      background-color: var(--btn-success);
      color: white;
    }
  }

  &.success {
    border: 1px solid var(--btn-success);
    color: var(--btn-success);

    &:hover {
      background-color: var(--btn-success);
      color: white;
    }
  }

  &.danger {
    border: 1px solid var(--btn-danger);
    color: var(--btn-danger);

    &:hover {
      background-color: var(--btn-danger);
      color: white;
    }
  }

  &.warning {
    border: 1px solid var(--btn-warning);
    color: var(--btn-warning);

    &:hover {
      background-color: var(--btn-warning);
      color: var(--greyish3);
    }
  }

  &.info {
    border: 1px solid var(--btn-info);
    color: var(--btn-info);

    &:hover {
      background-color: var(--btn-info);
      color: white;
    }
  }

  &.light {
    border: 1px solid var(--btn-light);
    color: var(--btn-info);

    &:hover {
      background-color: var(--btn-light);
      color: var(--greyish3);
    }
  }

  &.dark {
    border: 1px solid var(--btn-dark);
    color: var(--btn-info);

    &:hover {
      background-color: var(--btn-dark);
      color: white;
    }
  }
}
