.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--greyish11);
  display: flex;
  justify-content: center;
  overflow-y: auto;

  .inner {
    padding: 6% 16px 0 16px;
    max-width: 400px;
    box-sizing: border-box;
    width: 100%;

    @media screen and (max-width: 768px) {
      padding: 12% 16px 0 16px;
    }

    .logo {
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      font-size: 18px;
      font-weight: 500;
      color: var(--primary);

      img {
        width: 60px;
        margin-bottom: 16px;
        -webkit-box-reflect: below -8px linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.1));
      }
    }

    .info {
      margin-bottom: -12px;
      font-size: 12px;
      text-align: center;
      color: var(--greyish2);
    }

    .form {
      padding: 36px 0px;
      .input {
        display: flex;
        justify-content: space-between;
        position: relative;

        &:nth-child(2),
        &:nth-last-child(3) {
          input {
            border-bottom: unset;
          }
        }

        input {
          position: relative;
          display: block;
          box-sizing: border-box;
          width: 100%;
          height: 56px;
          padding: 0 16px;
          border: 1px solid var(--greyish8);

          &.password {
            padding-right: 54px;
          }

          @media screen and (max-width: 520px) {
            height: 52px;
          }
        }

        .view {
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          padding: 8px;
          cursor: pointer;
        }

        .countdown {
          cursor: default;
          position: absolute;
          right: 0;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 8px;
          font-size: 14px;
          color: var(--greyish4);
        }

        .check {
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          cursor: pointer;
          background-color: var(--primary);
          width: 90px;
          height: 28px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          font-size: 12px;
          color: white;

          &.checked {
            pointer-events: none;
            background-color: var(--greyish8);
          }

          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }

        .select {
          appearance: none;
          position: relative;
          display: block;
          box-sizing: border-box;
          width: 100%;
          height: 48px;
          padding: 0 40px 0 12px;
          border-radius: 4px;
          border: 1px solid var(--greyish8);
          color: var(--greyish2);
        }

        .birthday {
          display: inline-flex;
          position: relative;
          flex-basis: calc(100% / 3 - 6px);
        }

        &__arrow {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          padding: 8px;
          pointer-events: none;
        }
      }

      .guide {
        margin-top: 6px;
        .item {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #ff5656;

          &:last-child {
            margin-top: 8px;
          }

          img {
            width: 14px;
            margin-right: 2px;
          }
        }
      }

      .btn {
        position: relative;
        margin-top: 20px;
        border: 1px solid var(--primary);
        background-color: var(--primary);
        box-sizing: border-box;
        height: 48px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        color: white;
      }

      .etc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 8px;

        .label {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;
          cursor: pointer;

          .checkbox {
            position: relative;
            width: 16px;
            height: 16px;
            border: 1px solid var(--greyish8);
            border-radius: 50%;
            box-sizing: border-box;

            &::after {
              content: '';
              position: absolute;
              top: calc(50% - 1px);
              left: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
              width: 8px;
              height: 5px;
              border-bottom: 2px solid var(--greyish8);
              border-left: 2px solid var(--greyish8);
              box-sizing: border-box;
            }

            &.selected {
              border: 1px solid var(--primary);
              background-color: var(--primary);

              &::after {
                border-bottom: 1.5px solid white;
                border-left: 1.5px solid white;
              }
            }
          }

          .text {
            margin-left: 6px;
            font-size: 12px;
            font-weight: normal;
            color: var(--greyish4);
          }
        }
        .item {
          display: flex;
          align-items: center;

          .element {
            cursor: pointer;
            font-size: 12px;
            color: var(--greyish4);
          }
        }
      }
    }
  }
}
