.modal {
  position: relative;
  margin: 80px auto;
  padding: 16px;
  background-color: white;
  width: 600px;

  .title {
    font-size: 16px;
    color: var(--greyish2);
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
    min-height: 100px;
    row-gap: 5px;
    word-break: break-word;

    .message {
      padding: 4px 8px;
      white-space: pre-wrap;
      font-size: 14px;
      text-align: center;
      color: var(--greyish1);
      overflow: auto;
      span {
        font-weight: bold;
        color: var(--secondary);
      }

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--greyish3);
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }

    .select {
      padding: 0 8px 8px 8px;

      select {
        width: 100%;
        padding: 4px 8px;
        border: 1px solid var(--greyish8);
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 16px;

      .btn {
        padding: 6px 12px;
        border-radius: 4px;
        background-color: var(--secondary);
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        color: white;

        &:nth-child(2) {
          background-color: var(--greyish5);
          margin-left: 8px;
        }
      }
    }
  }

  .thumb {
    position: relative;
    padding-bottom: 56.25%;
    background-color: black;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .switch {
    display: flex;
    flex-direction: column;
    padding: 0 8px;

    &.out {
      margin-top: 12px;
      padding: unset;
    }

    .tab {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: unset;

      .item {
        cursor: pointer;
        flex: 1;
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--greyish9);
        font-size: 12px;
        color: var(--greyish5);

        &.active {
          background-color: white;
          color: var(--greyish1);
        }
      }
    }

    table {
      background-color: var(--greyish11);
      width: 100%;

      thead {
        tr {
          th {
            font-weight: bold;
          }
        }
      }

      tbody {
        tr {
          td {
            .subject {
              min-width: 80px;
            }

            .progress {
              position: relative;
              width: 240px;
              height: 8px;
              background-color: var(--greyish8);
              border-radius: 8px;
              overflow: hidden;

              .gauge {
                position: absolute;
                top: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background-color: var(--primary);
                transition: width 0.1s;

                &.complete {
                  background-color: var(--btn-success);
                }
              }
            }

            .percent {
              margin-left: 8px;
              width: 52px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
