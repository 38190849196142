.container {
  width: 220px;
  background-color: var(--greyish11);
  overflow-y: auto;
  transition: margin-left 0.2s;

  @media screen and (max-width: 900px) {
    width: 100%;
    display: none;
  }

  &.hide {
    margin-left: -220px;

    @media screen and (max-width: 900px) {
      margin-left: unset;
      display: block;
    }
  }

  .item {
    padding: 0 8px 0 24px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: var(--greyish3);

    &:hover {
      background-color: var(--greyish10);
    }

    &.active {
      color: var(--primary);
    }
  }
}
