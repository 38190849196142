.container {
  display: flex;
  width: 100%;
  height: calc(100% - 56px);
  margin-top: 56px;
  border-top: 1px solid transparent;
  position: relative;

  @media screen and (max-width: 900px) {
    display: block;
    min-height: 0;
    overflow-y: auto;
  }
}
