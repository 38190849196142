.container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(4, 9, 20, 0.025), 0 4px 12px rgba(4, 9, 20, 0.025), 0 4px 4px rgba(4, 9, 20, 0.025),
    0 4px 8px rgba(4, 9, 20, 0.025);

  .title {
    padding: 12px;
    border-bottom: 1px solid var(--greyish8);
    font-size: 16px;
    font-weight: bold;
    color: var(--greyish2);
  }

  .content {
    padding: 12px;
  }
}
