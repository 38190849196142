.container {
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    overflow: hidden;
    width: 64px;
    height: 64px;
    animation: loadingRotate 1.5s ease-in-out infinite;
    opacity: 1;

    .item {
      position: absolute;
      width: 64px;

      &:nth-child(1) {
        animation: symbolTranslate1 1.5s ease-in-out infinite;
      }

      &:nth-child(2) {
        animation: symbolRotate 1.5s ease-in-out infinite;
      }

      &:nth-child(3) {
        animation: symbolTranslate2 1.5s ease-in-out infinite;
      }
    }
  }
}

@keyframes loadingRotate {
  0% {
    opacity: 1;
  }

  33% {
    opacity: 1;
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
    opacity: 0;
  }
}

@keyframes symbolTranslate1 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }

  40% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  60% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes symbolTranslate2 {
  0% {
    opacity: 0;
    transform: translate(50%, 50%);
  }

  40% {
    opacity: 1;
    transform: translate(50%, 50%);
  }

  60% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes symbolRotate {
  0% {
    transform: scale(0) rotate(-360deg);
  }

  60% {
    transform: scale(1) rotate(0deg);
  }

  100% {
    transform: scale(1) rotate(0deg);
  }
}
