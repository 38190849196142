.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;

  .page {
    cursor: pointer;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--greyish6);
    margin: 0 2px;
    font-size: 12px;
    color: var(--greyish6);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      pointer-events: none;
      border: 1px solid var(--primary);
      color: var(--primary);
    }

    &.disabled {
      opacity: 0.15;
      cursor: default;
    }

    img {
      width: 18px;
    }
  }
}
